/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// overlay harm message ok
function hMessageOK() {
	jQuery(".wpcf7-response-output").addClass("hidden");
	
	jQuery("#hmessage .overlay-init").fadeOut("fast", function() {
		jQuery('html, body').stop().animate({scrollTop: 0}, {duration: 600, queue: false});
		jQuery("#hmessage .overlay-over").fadeIn("fast");
		
		jQuery.cookie('vbkhmessage', 'active', { expires: 2, path: '/' });		
		jQuery(".hmessage-reminder").fadeIn("fast");
	});
}

(function($) {
	// check if mobile device
	function isMobile() {
		return (!! Modernizr.touchevents);
	}

	// scrolling
	function scrollToPageSection(anchor, offset) {
		var currentOffset = (typeof offset === 'undefined') ? 34 : offset;
		var anchorPosition = Math.floor(anchor.offset().top) - currentOffset;
	
		$('html, body').stop().animate({scrollTop: anchorPosition}, {duration: 600, queue: false});	
	}
	
	// page transitions
	function setPageHeight(elem) {
		$("#pt-main").height(elem.height());
	}
	
	function setGlobalPageHeight(elem) {
		var resultHeight = 0;
		
		elem.each(function() {
			var curHeight = $(this).height();
			
			if (resultHeight < curHeight) {
				resultHeight = curHeight;
			}
		});
		
		$("#pt-main").height(resultHeight);
	}
		
	var globCurPage = 0;
	
	var PageTransitions = (function() {
	
		var $main = $( '#pt-main' ),
			$pages = $main.children( 'div.pt-page' ),
			animcursor = 1,
			pagesCount = $pages.length,
			current = 0,
			isAnimating = false,
			endCurrPage = false,
			endNextPage = false,
			/*bodyWidth = $(window).width(),*/
			animEndEventNames = {
				'WebkitAnimation' : 'webkitAnimationEnd',
				'OAnimation' : 'oAnimationEnd',
				'msAnimation' : 'MSAnimationEnd',
				'animation' : 'animationend'
			},
			// animation end event name
			animEndEventName = animEndEventNames[ Modernizr.prefixed( 'animation' ) ],
			// support css animations
			support = Modernizr.cssanimations;
		
		function resetPage( $outpage, $inpage ) {
			$outpage.attr( 'class', $outpage.data( 'originalClassList' ) );
			$inpage.attr( 'class', $inpage.data( 'originalClassList' ) + ' pt-page-current' );
			
			setPageHeight($inpage);
					
			// $("body, header.banner, .nav-primary, .banner-inner").width(bodyWidth).css("width", bodyWidth);
		}
		
		function onEndAnimation( $outpage, $inpage ) {
			endCurrPage = false;
			endNextPage = false;
			resetPage( $outpage, $inpage );
			isAnimating = false;
		}
	
		function nextPage(options ) {
			var animation = (options.animation) ? options.animation : options;
	
			if( isAnimating ) {
				return false;
			}
	
			isAnimating = true;
			
			var $currPage = $pages.eq( current );

			if(options.showPage){
				if( (options.showPage - 1) < pagesCount ) {
					current = options.showPage - 1;
				}
				else {
					current = 0;
				}
			}
			else{
				if( current < pagesCount - 1 ) {
					++current;
				}
				else {
					current = 0;
				}
			}
	
			var $nextPage = $pages.eq( current ).addClass( 'pt-page-current' ),
				outClass = '', inClass = '';
	
			switch( animation ) {
	
				case 1:
					outClass = 'pt-page-moveToLeft';
					inClass = 'pt-page-moveFromRight';
					break;
				case 2:
					outClass = 'pt-page-moveToRight';
					inClass = 'pt-page-moveFromLeft';
					break;
			}
	
			$currPage.addClass( outClass ).on( animEndEventName, function() {
				$currPage.off( animEndEventName );
				endCurrPage = true;
				if( endNextPage ) {
					onEndAnimation( $currPage, $nextPage );
				}
			} );
	
			$nextPage.addClass( inClass ).on( animEndEventName, function() {
				$nextPage.off( animEndEventName );
				endNextPage = true;
				if( endCurrPage ) {
					onEndAnimation( $currPage, $nextPage );
				}
			} );
	
			if( !support ) {
				onEndAnimation( $currPage, $nextPage );
			}
		}
	
		function init() {
			$pages.each( function() {
				var $page = $( this );
				$page.data( 'originalClassList', $page.attr( 'class' ) );
			} );
	
			$("#menu-hauptmenue a").on("click", function(e) {
		        e.preventDefault();
		        var id = $(this).attr("href");
		        
		        if (isAnimating) {
					return false;
				}
				
				var clickedMenuItemDOM = $(this).parent();
				var currentPageDOM = $("#pt-main .pt-page-current");
				var toLeft = 2, toRight = 1;
								
				var options = {showPage: false,  animation: false};
				
				if (clickedMenuItemDOM.hasClass("menu-item-ccustomer")) {
					options.showPage = 1;
				} else if (clickedMenuItemDOM.hasClass("menu-item-pcustomer")) {
					options.showPage = 2;
				} else if (clickedMenuItemDOM.hasClass("menu-item-aboutus")) {
					options.showPage = 3;
				}
				
				if (options.showPage !== false) {
					if (currentPageDOM.hasClass("pt-page-ccustomer")) {
						if (options.showPage > 1) {
							options.animation = toRight;
						}
					} else if (currentPageDOM.hasClass("pt-page-pcustomer")) {
						if (options.showPage === 1) {
							options.animation = toLeft;
						} else if (options.showPage === 3) {
							options.animation = toRight;
						}
					} else if (currentPageDOM.hasClass("pt-page-aboutus")) {
						if (options.showPage < 3) {
							options.animation = toLeft;
						}
					}
				}
				
				if (options.showPage !== false && options.animation !== false) {
					var moveIndex = options.showPage - 1;
					var movePercentage = moveIndex * 33.33333;
					
					globCurPage = moveIndex;
					
					if ($(window).width() >= 768) {
						$(".nav-primary .menu-item-bg").stop().animate({left: movePercentage + '%'}, 600, "swing");
					}
					
					$(".nav-primary li").removeClass("menu-item-active");
					$(".nav-primary li").eq(moveIndex).addClass("menu-item-active");
					
					if ($(window).width() >= 768) {
						scrollToPageSection($(id), 244);
					} else {
						scrollToPageSection($(id), 144);
					}
					
					//if ( ! isMobile()) {
						//setPageHeight($(id).closest(".pt-page"));
					//}
					
					nextPage(options);
				}
	        });
			
			$pages.eq( current ).addClass( 'pt-page-current' );	
		}
	
		init();
	
		return { 
			init : init,
			nextPage : nextPage,
		};
	
	})();

	function setStickyMenu() {
        if ($(window).width() >= 768) {
	        $("#nav-primary-sticky").fadeIn("fast", function() {
		        var movePercentage = globCurPage * 33.33333;
		        $(".nav-primary .menu-item-bg").stop().animate({left: movePercentage + '%'}, 600, "swing");
		        
		        $("#nav-primary-sticky").sticky({topSpacing: 104});
	        });
       	} else {
	       	$("#nav-primary-sticky").unstick();
       	}
	}
	
	function redrawMasonry() {
		$(".customersitem-container").masonry({
	        columnWidth: 1,
	        gutter: 10,
	        itemSelector: '.customersitem',
	        isAnimated: true,
	        isFitWidth: true
        });
	}
	
	function redrawHeader() {
		if ($(window).width() < 768) {
			$(".banner-inner").css("height", "134px");
		}
	}
	
	function onResizeWindow() {
		//if ( ! isMobile()) {
			setPageHeight($("#pt-main .pt-page-current"));
		/*} else {
			setGlobalPageHeight($("#pt-main .pt-page"));
		}*/
		
		setStickyMenu();
		redrawMasonry();
		redrawHeader();
	}

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // header size adjust on scroll
        var scrollMin = 168;
	    var origMaxMenuHeight = 134;
	    var origMinMenuHeight = 104;
	    var resultMenuHeight;
	        
        $(window).scroll(function() {
	        if ($(window).width() >= 768) {
		        resultMenuHeight = origMaxMenuHeight - ($(window).scrollTop() - scrollMin);
			    
			    if (resultMenuHeight > origMaxMenuHeight) { 
				    resultMenuHeight = origMaxMenuHeight;
				} else if (resultMenuHeight < origMinMenuHeight) {
					resultMenuHeight = origMinMenuHeight;
				}
			  
			    $(".banner-inner").css("height", resultMenuHeight + "px");
			}
        });
        
        $(window).scroll();
        
        // set mobile classes
        if (isMobile()) {
        	$("html").addClass("is-mobile");
        }
        
        // Stop the animation if the user scrolls. Defaults on .stop() should be fine
	  	var $viewport = $('html, body');
		
		$viewport.bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(e){
		    if ( e.which > 0 || e.type === "mousedown" || e.type === "mousewheel"){
		         $viewport.stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
		    }
		});
		
		$(".scroll").on("click", function(e) {
			e.preventDefault();
			var id = $(this).attr("href");
			
			scrollToPageSection($(id));			
		});
        
        // do that sticky thing
        if ($(window).width() >= 768) {
       		$("#nav-primary-sticky").sticky({topSpacing: 104});
       	}
        
        // mobile menu trigger
        $(".nav-primary-trigger").on("click", function() {
	        $("a.close-overlay").trigger("click");
	        $(".nav-primary").fadeIn("fast");
        });
        
        $(".nav-primary a").on("click", function() {
	        if ($(window).width() < 768) {
		        $(".nav-primary").fadeOut("fast");
		    }
        });
        
        $(".mobile-menu-close").on("click", function() {
	        if ($(window).width() < 768) {
		        $(".nav-primary").fadeOut("fast");
		    }
        });
        
        
        $(window).load(function() {
	        // masonry
	        $(".customersitem-container").masonry({
		        columnWidth: 1,
		        gutter: 10,
		        itemSelector: '.customersitem',
		        isAnimated: true,
		        isFitWidth: true
	        });
	        
	        // set transition page height because of position absolute content
	        //if ( ! isMobile()) {
		        setPageHeight($("#pt-main .pt-page-current"));
	        /*} else {
		        setGlobalPageHeight($("#pt-main .pt-page"));
	        }*/
	    });
        
        // harm message reminder
		var hmessageCookie = $.cookie('vbkhmessage');
		
		if (typeof hmessageCookie !== 'undefined') {
			if (hmessageCookie === 'active') {
				$(".hmessage-reminder").fadeIn("fast");
			}
		}
		
		$(".hmessage-reminder-gradient a, .close-hmessage-reminder").on({
			mouseenter: function() {
				$(".close-hmessage-reminder").css("display", "block");
			},
			mouseleave: function() {
				$(".close-hmessage-reminder").css("display", "none");
			}
		});
						
		$(".close-hmessage-reminder").on("click", function(e) {
			e.preventDefault();
			
			$.removeCookie('vbkhmessage', { path: '/' });
			$(".hmessage-reminder").fadeOut("slow");
		});
		
		// open/close drawer
		$(".drawer-btn").on("click", function() {
			$(this).prev().toggleClass("open");
			$(this).fadeOut("slow");
			
			$(this).one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function(e) {
				redrawMasonry();
			});
		});
		
		// overlay
		$("a").on("click", function(e) {
			var parent = $(this).parent();
			if (parent.hasClass("open-overlay")) {
				e.preventDefault();
				
				$(".open-overlay").each(function() {
					$(this).removeClass("active");
				});
				
				$("#overlay-container section").each(function() {
					$(this).scrollTop(0);
				});
			
				parent.addClass("active");
				var elem = $("#overlay-container");
				var id = $(this).attr("href");
								
				elem.fadeOut("fast", function() {
					$("body").removeClass("noscroll");
					
					elem.removeClass("blue");
					elem.children("section").each(function() {
						if ( ! $(this).hasClass("hidden")) {
							$(this).addClass("hidden");
						}
					});
					
					$("body").addClass("noscroll");
							
					$(id).removeClass("hidden");
					if ($(id).hasClass("blue")) {
						elem.addClass("blue");
					}
					
					$("#hmessage .overlay-init").css("display", "block");
					$("#hmessage .overlay-over").css("display", "none");
					
					if (parent.hasClass("hmessage-only")) {
						$("#hmessage .overlay-init").css("display", "none");
						$("#hmessage .overlay-over").css("display", "block");
					}
					
					elem.css("top", $(".banner-inner").height() + "px");					
					elem.fadeIn("slow");
				});
			}
		});
		
		$("a.close-overlay").on("click", function(e) {
			e.preventDefault();
			
			var elem = $("#overlay-container");
			
			$("body").removeClass("noscroll");
			
			elem.children("section").each(function() {
				$(this).scrollTop(0);
			});
			
			$(".open-overlay").each(function() {
				$(this).removeClass("active");
			});
			
			elem.fadeOut("fast", function() {
				elem.removeClass("blue");
				elem.children("section").each(function() {
					if ( ! $(this).hasClass("hidden")) {
						$(this).addClass("hidden");
					}
				});
			});
		});
		
		$("#overlay-container section").scroll(function() {
			var newWidth = ($(this).scrollTop() === 0) ? 200 : 30;
			$(".close-overlay-container").css("width", newWidth + "px");
		});
		
		var emailVal = "";
		var phoneVal = "";
		
		$("#contact .cbtn").on("click", function(e) {
			e.preventDefault();
			var emailPlaceholder = "office@vb-kaltenegger.at";
			var phonePlaceholder = "000000000000";
			
			$("#contact .cbtn").each(function() {
				$(this).removeClass("active");
			});
			
			$(this).addClass("active");
			
			if ($(this).hasClass("btn-sel-phone")) {
				emailVal = $(".sel-email input").val();
				$(".sel-email").removeClass("hidden").addClass("hidden");
				$(".sel-email input").val(emailPlaceholder);
				
				$(".sel-phone input").val(phoneVal);
				$(".sel-phone").removeClass("hidden");
			} else {
				phoneVal = $(".sel-phone input").val();
				$(".sel-phone").removeClass("hidden").addClass("hidden");
				$(".sel-phone input").val(phonePlaceholder);
				
				$(".sel-email input").val(emailVal);
				$(".sel-email").removeClass("hidden");				
			}
		});
		
		$("#contact .sel-phone input").val("000000000000");
						
		// window on resize functions		
		var rtime;
		var timeout = false;
		var delta = 200;
		
		function resizeend() {
			if (new Date() - rtime < delta) {
				setTimeout(resizeend, delta);
			} else {
				timeout = false;
				onResizeWindow();
			}
		}
		
		$(window).resize(function() {
			rtime = new Date();
			
			if (timeout === false) {
				timeout = true;
				setTimeout(resizeend, delta);
			}
		});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
